


















import { Vue, Prop, Component } from 'vue-property-decorator'
import PortfolioFactory from '@/factories/portfolio'
import MoodleAPI from '@/api/requests/moodle'
import { AgGridVue } from 'ag-grid-vue'
import AgGridFactory from '@/factories/agGridFactory'
import ExcelAPI from '@/api/requests/excel'

@Component({
  components: {
    AgGridVue
  }
})
export default class MoodleStatisticModal extends Vue {
  @Prop({ type: String, default: 'Статистика по модулям' })
  readonly title!: string
  @Prop(String) courseId!: string
  private courseModel = PortfolioFactory.emptyCourses()
  private rowData: any[] = []

  private defaultDefs = [
    {
      headerName: 'Модуль',
      field: 'itemName',
      colId: 'itemName',
      suppressSizeToFit: true
    },
    {
      headerName: 'Минимальный балл',
      field: 'gradeMin',
      colId: 'gradeMin',
      suppressSizeToFit: true
    },
    {
      headerName: 'Максимальный балл',
      field: 'gradeMax',
      colId: 'gradeMax',
      suppressSizeToFit: true
    },
    {
      headerName: 'Оценка',
      field: 'gradeRaw',
      colId: 'gradeRaw',
      suppressSizeToFit: true
    }
  ]

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    pagination: true,
    paginationPageSize: 15
  }

  private get columnDefs() {
    return this.defaultDefs
  }

  public async openStatisticModal(params: any) {
    this.courseModel = params
    const courseResult = await MoodleAPI.getCourseResultsByStudent(
      params.userId,
      params.moodleId
    )
    const gradeItems: any[] = courseResult?.data.gradeItems ?? []
    this.rowData = gradeItems
  }

  public async downloadXlsxReport() {
    await ExcelAPI.getCourseResultsByStudent(
      (this.courseModel as any).userId,
      (this.courseModel as any).moodleId
    )
  }
}
