export default class PortfolioFactory {
  public static emptyCourses() {
    return [] as any[]
  }
  public static emptyEvents() {
    return [
      {
        id: '',
        title: '',
        eventDate: ''
      }
    ]
  }
}
